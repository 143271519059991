<template>
    <!-- 导航 -->
    <page-head :title="store.state.tenantData.name" />
    <content-bg>
        <template v-slot:content>
            <team-list :teamList="teamsList" @callback="menuClick"></team-list>
            <div class="menuList">
                <div v-if="showData && showData.showList"
                     :key="item.id"
                     v-for="(item,index) in showData.showList"
                     class="menuItem" @click="menuClick(item)">
                    <index-number>
                        <template v-slot:number>{{ index + 1 }}</template>
                    </index-number>
                    <menu-card>
                        <template v-slot:icon><img class="icon" :src="item.icon" alt=""></template>
                        <template v-slot:label>
                            <p class="label YouSheBiaoTiHei"
                               :class="item.name.length>6 ? 'small' : ''">{{ item.name }}</p>
                        </template>
                    </menu-card>
                </div>

            </div>
        </template>
    </content-bg>
    <pagination-com :current-page="showData.page" :total-page="showData.totalPage"
                    @changePage="changePage"></pagination-com>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "team",
    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            teamsList: [],
            defaultList: [
                {
                    name: '机构介绍',
                    id: 1,
                    icon: require('@i/firstMenu/xunlian.svg'),
                    disable: true,
                    hidden: true,
                    router: {
                        name: 'introduce',
                        path: '/introduce/index'
                    },
                    pro: true,
                    basic: true
                },
                {
                    name: '训练营',
                    id: 2,
                    icon: require('@i/firstMenu/xunlianying.svg'),
                    disable: false,
                    hidden: true,
                    router: {
                        name: 'camp',
                        path: '/camp/index'
                    },
                    pro: true,
                    basic: false
                },
                {
                    name: '精英人才库',
                    id: 3,
                    icon: require('@i/firstMenu/rencaiku.svg'),
                    disable: false,
                    hidden: true,
                    router: {
                        name: 'talent',
                        path: '/talent/index'
                    },
                    pro: true,
                    basic: false
                },
                {
                    name: '校区',
                    id: 7,
                    icon: require('@i/firstMenu/school.svg'),
                    disable: true,
                    hidden: true,
                    router: {
                        name: 'school',
                        path: '/school/index'
                    },
                    pro: false,
                    basic: true
                },
                {
                    name: '培训',
                    id: 4,
                    icon: require('@i/firstMenu/peixun.svg'),
                    disable: true,
                    hidden: true,
                    router: {
                        name: 'school',
                        path: '/school/index'
                    },
                    pro: false,
                    basic: false
                },
                {
                    name: '比赛报告',
                    id: 5,
                    icon: require('@i/firstMenu/matchReport.svg'),
                    disable: true,
                    hidden: true,
                    router: {
                        name: 'matchReport',
                        path: '/matchReport/index'
                    },
                    pro: true,
                    basic: false
                },
                {
                    name: 'SmartPitch',
                    id: 7,
                    icon: require('@i/secondMenu/exerciseList.svg'),
                    disable: true,
                    hidden: true,
                    router: {
                        name: 'smartPitch',
                        path: '/smartPitch/index'
                    },
                    pro: false,
                    basic: false
                },
                {
                    name: '训练教案',
                    id: 6,
                    icon: require('@i/secondMenu/exerciseList.svg'),
                    disable: false,
                    hidden: true,
                    router: {
                        name: 'exerciseList',
                        path: '/exerciseList/index'
                    },
                    pro: true,
                    basic: true
                },
            ],

            menuList: [],

            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 5
            },
            showData: {}
        });


        const getTeamList = () => {
            proxy.$server.getTeamList().then((res) => {
                let teamsList = [];

                res.data.map(item => {
                    if (!item.hidden) {
                        teamsList.push({
                            ...item,
                            icon: require('@i/firstMenu/teamIcon.svg'),
                            router: {
                                name: 'mainMenu',
                                path: '/menu/index',
                                query: {
                                    type: 'team', // team/camp 普通梯队或者训练营
                                }
                            }
                        })
                    }
                })
                state.defaultList.map(item => {
                    if (store.state.tenantData.type === 'PRO' && item.pro) {
                        state.menuList.push(item)
                    }else if (store.state.tenantData.type === 'BASIC' && item.basic) {
                        state.menuList.push(item)
                    }
                })
                state.teamsList = [...teamsList];
                state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
            })
        }

        watch(() => store.state.identifyData, (val, old) => {
            console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.showData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: menuClick
            })
        })

        onMounted(() => {
            getTeamList();
        })

        const changePage = (page) => {
            state.page.pageNum = page;
            state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
        }

        const menuClick = (item) => {
            store.commit('setTeamData', item)
            router.push({
                ...item.router
            })
        }


        return {
            ...toRefs(state),
            store,
            changePage,
            menuClick
        }
    }
}
</script>

<style scoped lang="scss">

::v-deep .menuItem {
    width: 240px;
    height: 245px;
    margin-right: 18px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 36px;
    text-align: center;

    &:nth-child(5n+1) {
        margin-left: 43px;
    }

    .cardItem {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .icon {
        width: 130px;
        height: 130px;
        margin-top: 30px;
        margin-bottom: 16px;
    }

    .label {
        font-weight: 400;
        font-size: 28px;
        line-height: 39px;
        padding-left: 20px;
        padding-right: 20px;

        &.small {
            font-size: 24px;
            line-height: 27px;
        }
    }
}

::v-deep.indexNum {
    left: 16px;
    top: 16px;
}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}


.menuList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 54px;
    width: 100%;
}
</style>